import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Box,
  Button,
  Chip,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as DexscreenerSvg } from "../../assets/images/svg/dexscreener.svg";
import { ReactComponent as HeartIcon } from "../../assets/images/svg/heart.svg";
import { ReactComponent as BuyIcon } from "../../assets/images/svg/buy.svg";
import { ReactComponent as OverviewIcon } from "../../assets/images/svg/overview.svg";
import { ReactComponent as TelegramSvg } from "../../assets/images/svg/telegram.svg";
import { ReactComponent as TwitterSvg } from "../../assets/images/svg/twitter.svg";
import LogoIcon from "../Logo/LogoIcon";

import { ReactComponent as GitIcon } from "../../assets/images/svg/git.svg";
import { ReactComponent as MenuIcon } from "../../assets/images/svg/hamburger.svg";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  boxShadow: "none",
  height: "100%",
  width: "fit-content",
  border: "1px solid #D0D7DE",
  "&:hover": {
    backgroundColor: "#D0D7DE ",
  },
}));

const DexscreenerStyled = styled(DexscreenerSvg)(({ theme }) => ({
  height: 20,
  width: 20,
}));
const TelegramStyled = styled(TelegramSvg)(({ theme }) => ({
  height: 20,
  width: 20,
}));
const TwitterStyled = styled(TwitterSvg)(({ theme }) => ({
  height: 20,
  width: 20,
}));
const BuyStyled = styled(BuyIcon)(({ theme }) => ({
  height: 20,
  width: 20,
}));

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
  fontSize: "1rem",
  fontWeight: "500",
  color: theme.palette.text.secondary,
  borderBottom: "1px solid transparent",
  padding: 10,

  "&:hover": {
    color: "#1F2328",
  },

  "& svg": {
    fontSize: "1.5rem",
    margin: "0 4px 0 0",
  },

  "&.active": {
    color: "#1F2328",
    borderBottom: "1px solid #FD8C73",
  },
}));

const Header = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  return (
    <AppBar sx={props.sx} elevation={0} className={props.customClass}>
      {/* Top Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          m: 2,
        }}
      >
        <Stack direction={"row"} alignItems={"center"} gap={2}>
          <Box sx={{ cursor: "pointer" }}>
            <MenuIcon />
          </Box>
          <Box
            component={Link}
            to="/home"
            sx={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              gap: 1.5,
              cursor: "pointer",
            }}
          >
            <LogoIcon />
            <Typography
              variant="h5"
              fontWeight={500}
              sx={{ color: "#000", textDecoration: "none" }}
            >
              Octocat
            </Typography>
          </Box>
        </Stack>

        <Stack direction={"row"} alignItems={"center"} gap={1.5}>
          {!isMobile ? (
            <TextField
              sx={{ width: { xs: 140, md: "auto" } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              placeholder="Type '/' to search"
              size="small"
            />
          ) : (
            <StyledButton>
              <SearchIcon fontSize="small" sx={{ color: "#636C76" }} />
            </StyledButton>
          )}
          {!isMobile && (
            <>
              <StyledButton
                component={Link}
                to="https://t.me/OctoCatOfficial"
                target="_blank"
              >
                <TelegramStyled />
              </StyledButton>

              <StyledButton
                component={Link}
                to="https://twitter.com/Octocatofficial"
                target="_blank"
              >
                <TwitterStyled />
              </StyledButton>

              <StyledButton
                component={Link}
                to="https://dexscreener.com/"
                target="_blank"
              >
                <DexscreenerStyled />
              </StyledButton>
            </>
          )}

          {/* {!isMobile && (
            <>
              <Box
                sx={{
                  height: "80%",
                  width: "1px",
                  backgroundColor: "#D0D7DE",
                }}
              />

              <StyledButton
                variant="contained"
                endIcon={<ArrowDropDownIcon sx={{ color: "#636C76" }} />}
              >
                <AddIcon sx={{ color: "#636C76" }} />
              </StyledButton>

              <StyledButton>
                <IssuesIcon />
              </StyledButton>

              <StyledButton>
                <PullReqIcon />
              </StyledButton>
            </>
          )} */}

          {/* <StyledButton>
            <NotiFicationIcon />
          </StyledButton> */}

          <Box>
            <GitIcon />
          </Box>
        </Stack>
      </Box>

      {/* Second Header */}
      <Stack
        direction={"row"}
        gap={1}
        alignItems={"center"}
        flexWrap={"wrap"}
        justifyContent={"flex-start"}
        flexGrow={1}
        sx={{ mx: { md: 2, sm: 0 } }}
      >
        <Stack direction={"row"} alignItems={"center"} gap={2}>
          <MenuItemStyled
            component={Link}
            to="/home"
            className={location.pathname.includes("home") && "active"}
          >
            <OverviewIcon />
            Home
          </MenuItemStyled>
          <MenuItemStyled
            component={Link}
            to="/buy"
            className={location.pathname.includes("buy") && "active"}
          >
            <BuyStyled />
            Buy
            {/* <Chip label="1" size="small" /> */}
          </MenuItemStyled>
          <MenuItemStyled
            component={Link}
            to="/octocat"
            className={location.pathname.includes("octocat") && "active"}
          >
            <OverviewIcon />
            PFP Generator
          </MenuItemStyled>
          {!isMobile ? (
            <>
              <MenuItemStyled>
                <HeartIcon />
                Lives remaining
                <Chip label="9" size="small" />
              </MenuItemStyled>
            </>
          ) : (
            <IconButton aria-label="more" id="long-button">
              <MoreHorizIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </AppBar>
  );
};

export default Header;
