import React from "react";
import { Grid, styled } from "@mui/material";
//
const IframeStyled = styled("iframe")(({ theme }) => ({
  width: "100%",
  height: "100vh",
  border: "none",

  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(5),
  },
}));

const Buy = () => {
  return (
    <Grid
      container
      sx={{
        width: "100%",
        height: "100vh",
        padding: 0,
      }}
    >
      <Grid item xs={12}>
        <IframeStyled
          src="https://raydium.io/swap/"
          frameBorder="0"
          allowFullScreen
        ></IframeStyled>
      </Grid>
    </Grid>
  );
};

export default Buy;
