import { Stack, Typography } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <Stack
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      mt={8}
      mb={4}
      mx={2}
    >
      <Typography variant="body2" textAlign={"center"} sx={{ maxWidth: 1440 }}>
        Octocat ($OCTO) is a meme coin with no intrinsic value or expectation of
        financial return. This content is not endorsed or sponsored by any
        official entity - it is a parody and should be viewed as a work of
        satire. There is no formal team or roadmap. The coin is completely
        useless and for entertainment purposes only.
      </Typography>
    </Stack>
  );
};

export default Footer;
