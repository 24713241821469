import { Box, Container, experimentalStyled } from "@mui/material";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { TopbarHeight } from "../assets/global/Theme-variable";
import Header from "./Header/Header";
import Footer from "./Footer";

const MainWrapper = experimentalStyled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  overflow: "hidden",
  width: "100%",
}));
const PageWrapper = experimentalStyled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "1 1 auto",
  overflow: "hidden",
  backgroundColor: "rgb(250, 251, 251)",

  [theme.breakpoints.up("lg")]: {
    paddingTop: TopbarHeight,
  },
  [theme.breakpoints.down("lg")]: {
    paddingTop: "100px",
  },
}));

const Layout = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  return (
    <MainWrapper>
      <Header
        sx={{
          backgroundColor: "#ffffff",
          boxShadow: "rgba(90, 114, 123, 0.11) 0px 7px 30px 0px",
        }}
        toggleSidebar={() => setSidebarOpen(!isSidebarOpen)}
      />

      <PageWrapper>
        <Box sx={{ minHeight: "calc(100vh - 230px)" }}>
          <Outlet />
        </Box>
        <Footer />
      </PageWrapper>
    </MainWrapper>
  );
};

export default Layout;
