import React from "react";
const LogoIcon = (props) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.975 -0.0100098C25.8122 -0.0100098 32.97 7.14775 32.97 15.985C32.9691 19.3363 31.9173 22.6031 29.9626 25.3253C28.0079 28.0476 25.2487 30.0883 22.0734 31.1602C21.2736 31.3202 20.9737 30.8204 20.9737 30.4005C20.9737 29.8607 20.9937 28.1412 20.9937 26.0019C20.9937 24.5023 20.4939 23.5426 19.9141 23.0428C23.473 22.6429 27.2118 21.2833 27.2118 15.1453C27.2118 13.3858 26.592 11.9662 25.5723 10.8466C25.7322 10.4467 26.2921 8.80723 25.4123 6.60792C25.4123 6.60792 24.0728 6.16806 21.0137 8.24741C19.7341 7.88752 18.3745 7.70758 17.015 7.70758C15.6554 7.70758 14.2958 7.88752 13.0162 8.24741C9.95718 6.18805 8.6176 6.60792 8.6176 6.60792C7.73787 8.80723 8.2977 10.4467 8.45765 10.8466C7.43796 11.9662 6.81816 13.4058 6.81816 15.1453C6.81816 21.2633 10.537 22.6429 14.0959 23.0428C13.636 23.4427 13.2162 24.1424 13.0762 25.1821C12.1565 25.602 9.85721 26.2818 8.41766 23.8625C8.11775 23.3827 7.21803 22.203 5.95843 22.223C4.61885 22.243 5.4186 22.9828 5.97842 23.2827C6.65821 23.6626 7.43796 25.0821 7.61791 25.542C7.93781 26.4417 8.97748 28.1612 12.9962 27.4214C12.9962 28.761 13.0162 30.0206 13.0162 30.4005C13.0162 30.8204 12.7163 31.3002 11.9166 31.1602C8.73084 30.0998 5.95989 28.0632 3.99682 25.3393C2.03374 22.6154 0.97822 19.3426 0.979983 15.985C0.979983 7.14775 8.13774 -0.0100098 16.975 -0.0100098Z"
        fill="#1F2328"
      />
    </svg>
  );
};

export default LogoIcon;
