import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

import { ReactComponent as OrgSvg } from "../../assets/images/svg/org.svg";
import { ReactComponent as RoundSvg } from "../../assets/images/svg/round.svg";
import { ReactComponent as SmileSvg } from "../../assets/images/svg/smile.svg";
import { ReactComponent as SmileIcon } from "../../assets/images/svg/smileIcon.svg";

import { Link } from "react-router-dom";
import { ReactComponent as ChartSvg } from "../../assets/images/svg/chart.svg";
import { ReactComponent as GitCSvg } from "../../assets/images/svg/git-c.svg";
import { ReactComponent as TelegramSvg } from "../../assets/images/svg/telegram.svg";
import { ReactComponent as TwitterSvg } from "../../assets/images/svg/twitter.svg";
import { ReactComponent as BuyIcon } from "../../assets/images/svg/buy.svg";

import Contribution from "./Contribution";
import ContributionActivity from "./ContributionActivity";

//

const TelegramStyled = styled(TelegramSvg)(() => ({
  height: 18,
  width: 18,
}));
const TwitterStyled = styled(TwitterSvg)(() => ({
  height: 18,
  width: 18,
}));
const ChartStyled = styled(ChartSvg)(() => ({
  height: 18,
  width: 18,
}));
const BuyStyled = styled(BuyIcon)(({ theme }) => ({
  height: 20,
  width: 20,
}));

const UserLogoStyled = styled(SmileSvg)(({ theme }) => ({
  height: 300,
  width: 300,
  [theme.breakpoints.down("md")]: {
    height: 125,
    width: 125,
  },
}));

const YellowRoundSvg = styled(RoundSvg)(() => ({
  fill: "#F1E05A",
}));
const PurrRoundSvg = styled(RoundSvg)(() => ({
  fill: "#31C6BD",
}));
const Git1 = styled(GitCSvg)(() => ({
  fill: "#EBEDF0",
}));
const Git2 = styled(GitCSvg)(() => ({
  fill: "#9BE9A8",
}));
const Git3 = styled(GitCSvg)(() => ({
  fill: "#40C463",
}));
const Git4 = styled(GitCSvg)(() => ({
  fill: "#30A14E",
}));
const Git5 = styled(GitCSvg)(() => ({
  fill: "#216E39",
}));

const UserWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    flexDirection: "row",
  },
}));
const EditButton = styled(Button)(() => ({
  backgroundColor: "#F6F8FA",
  border: "1px solid #D0D7DE",
  boxShadow: "none",
  borderRadius: "6px",
  color: "#24292F",
}));
const SettingButton = styled(Button)(() => ({
  backgroundColor: "transparent",
  border: "1px solid transparent",
  boxShadow: "none",
  color: "#24292F",
  whiteSpace: "nowrap",
}));
const TypoH5 = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  fontWeight: 300,
  color: "#636C76",
}));

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  background: "transparent",
  borderRadius: 6,
  color: "#000",
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1),
  "&:hover": {
    background: "#0969DA",
    color: "#fff",
  },
  "&.Mui-selected": {
    background: "#0969DA",
    color: "#fff",
  },
}));

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container
      sx={{
        paddingTop: "20px",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <UserWrapper>
            {!isMobile && <UserLogoStyled />}
            {isMobile && <UserLogoStyled />}
            {/* <Box component={"img"} src={UserLogo} /> */}
            <Typography
              sx={{
                color: "#636C76",
              }}
              variant="h1"
              fontWeight="bold"
            >
              Octocat
            </Typography>
          </UserWrapper>

          <Stack direction={"column"} gap={2} my={3}>
            <EditButton
              fullWidth
              startIcon={<SmileIcon />}
              // sx={{
              //   justifyContent: "flex-start",
              //   px: 3,
              // }}
            >
              Eight paws, one commit. #OctoPush
            </EditButton>
            <EditButton
              fullWidth
              component={Link}
              to="/buy"
              startIcon={<BuyStyled />}
            >
              Buy
            </EditButton>
          </Stack>
          {!isMobile && (
            <Stack
              sx={{
                borderTop: "1px solid #D0D7DE",
              }}
              py={1}
              gap={2}
            >
              <Typography variant="h4" fontWeight={"600"}>
                Organizations
              </Typography>

              <Box>
                <OrgSvg />
              </Box>
            </Stack>
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Card
                  sx={{
                    p: 0,
                    m: 0,
                    border: "1px solid #D0D7DE",
                    boxShadow: "none",
                  }}
                >
                  <CardContent>
                    <Stack
                      direction="row"
                      alignItems={"flex-start"}
                      justifyContent={"space-between"}
                    >
                      <Stack direction={"column"} gap={1}>
                        <Typography
                          variant="h4"
                          fontWeight={600}
                          sx={{
                            color: "#0969DA",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          component={Link}
                          to="/octocat"
                        >
                          Octocat
                        </Typography>
                        <TypoH5>100% Fair Launched</TypoH5>
                        <TypoH5>Liquidity Burned</TypoH5>
                      </Stack>
                      <Chip label="Public" variant="outlined" size="small" />
                    </Stack>

                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      mt={5}
                      mb={0}
                      gap={1}
                    >
                      <YellowRoundSvg />
                      <TypoH5>Meow</TypoH5>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  sx={{
                    p: 0,
                    m: 0,
                    border: "1px solid #D0D7DE",
                    boxShadow: "none",
                  }}
                >
                  <CardContent>
                    <Stack
                      direction="row"
                      alignItems={"flex-start"}
                      justifyContent={"space-between"}
                    >
                      <Stack direction={"column"} gap={1}>
                        <Typography
                          variant="h4"
                          fontWeight={600}
                          sx={{ color: "#0969DA" }}
                        >
                          Octocat Links
                        </Typography>
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                          <TelegramStyled />
                          <TypoH5
                            component={Link}
                            to="https://t.me/OctoCatOfficial"
                            target="_blank"
                          >
                            Telegram
                          </TypoH5>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                          <TwitterStyled />
                          <TypoH5
                            component={Link}
                            to="https://twitter.com/Octocatofficial"
                            target="_blank"
                          >
                            Twitter
                          </TypoH5>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                          <ChartStyled />
                          <TypoH5
                            component={Link}
                            to="https://dexscreener.com/"
                            target="_blank"
                          >
                            Chart
                          </TypoH5>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                          <PurrRoundSvg />
                          <TypoH5>Purr</TypoH5>
                        </Stack>
                      </Stack>
                      <Chip label="Public" variant="outlined" size="small" />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Grid container mt={2} spacing={2}>
              <Grid item xs={12} md={9}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  mb={2}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#1F2328",
                      maxWidth: { lg: "100%", md: "200px" },
                    }}
                  >
                    3 contributions in the last year
                  </Typography>
                  <SettingButton endIcon={<ArrowDropDownIcon />}>
                    Contribution settings{" "}
                  </SettingButton>
                </Stack>

                <Box>
                  <Card
                    sx={{
                      p: 0,
                      m: 0,
                      border: "1px solid #D0D7DE",
                      boxShadow: "none",
                    }}
                  >
                    <CardContent>
                      <Contribution />

                      <Stack
                        mt={2}
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <TypoH5>Cats can dev too</TypoH5>

                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                          <TypoH5>Less</TypoH5>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={0.2}
                          >
                            <Git1 />
                            <Git2 />
                            <Git3 />
                            <Git4 />
                            <Git5 />
                          </Stack>
                          <TypoH5>More</TypoH5>
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>

              <Grid item xs={12} md={3}>
                {!isMobile && (
                  <MenuList>
                    <MenuItemStyled selected>
                      <ListItemText>2024</ListItemText>
                    </MenuItemStyled>
                    <MenuItemStyled>
                      <ListItemText>2023</ListItemText>
                    </MenuItemStyled>
                    <MenuItemStyled>
                      <ListItemText>2022</ListItemText>
                    </MenuItemStyled>
                  </MenuList>
                )}
              </Grid>
            </Grid>

            <Grid container mt={2}>
              <Grid item xs={12}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="h4" sx={{ color: "#1F2328" }}>
                    Contribution activity
                  </Typography>
                  {isMobile && (
                    <SettingButton endIcon={<ArrowDropDownIcon />}>
                      2024{" "}
                    </SettingButton>
                  )}{" "}
                </Stack>
              </Grid>

              <Grid container mt={2} alignItems={"center"}>
                <Grid item xs={2}>
                  <Typography>
                    <b>April</b> 2024
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Divider />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <ContributionActivity />
              </Grid>
            </Grid>
          </>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
