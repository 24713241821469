import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepConnector from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { Grid, IconButton, Typography } from "@mui/material";

import { ReactComponent as ActivitySvg } from "../../assets/images/svg/activity.svg";
import { ReactComponent as CollapseSvg } from "../../assets/images/svg/collapse.svg";
import { ReactComponent as ForkSvg } from "../../assets/images/svg/fork.svg";
import { ReactComponent as RoundSvg } from "../../assets/images/svg/round.svg";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  width: 2,
  marginLeft: 20,
  backgroundColor: "#D0D7DE",
  height: "100%",
}));

const SplRoundSvg = styled(RoundSvg)(({ theme }) => ({
  fill: "#9631C6",
}));
const SocialRoundSvg = styled(RoundSvg)(({ theme }) => ({
  fill: "#3178C6",
}));
const ArtRoundSvg = styled(RoundSvg)(({ theme }) => ({
  fill: "#E58B65",
}));
const ColorlibStepIconRoot = styled("div")(({ theme }) => ({
  backgroundColor: "#EAEEF2",
  zIndex: 1,
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
}));
const StepLabelStyle = styled(StepLabel)(() => ({
  fontSize: "16px !important",
  color: "#1F2328 !important",
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      <ActivitySvg />
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export default function ContributionActivity() {
  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        orientation="vertical"
        // alternativeLabel
        activeStep={1}
        connector={<ColorlibConnector />}
      >
        <Step>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <StepLabelStyle StepIconComponent={ColorlibStepIcon}>
              Created 1 Coin
            </StepLabelStyle>

            <IconButton>
              <CollapseSvg />
            </IconButton>
          </Stack>

          <Grid container>
            <Grid item xs={4}>
              <Stack ml={6} direction={"row"} alignItems={"center"} gap={1}>
                <ForkSvg />
                <Typography variant="h6" sx={{ color: "#0969DA" }}>
                  Octocat
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack
                justifyContent={"flex-end"}
                direction={"row"}
                alignItems={"center"}
                gap={1}
              >
                <SplRoundSvg />
                <Typography>SPL</Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack
                justifyContent={"flex-end"}
                direction={"row"}
                alignItems={"center"}
                gap={1}
              >
                <Typography>Apr 24</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Step>

        <Step>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <StepLabelStyle StepIconComponent={ColorlibStepIcon}>
              Created Octocat Telegram and Twitter Accounts
            </StepLabelStyle>

            <IconButton>
              <CollapseSvg />
            </IconButton>
          </Stack>

          <Grid container>
            <Grid item xs={4}>
              <Stack ml={6} direction={"row"} alignItems={"center"} gap={1}>
                <ForkSvg />
                <Typography variant="h6" sx={{ color: "#0969DA" }}>
                  Telegram
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack
                justifyContent={"flex-end"}
                direction={"row"}
                alignItems={"center"}
                gap={1}
              >
                <SocialRoundSvg />
                <Typography>Social</Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack
                justifyContent={"flex-end"}
                direction={"row"}
                alignItems={"center"}
                gap={1}
              >
                <Typography>Apr 24</Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container mt={1.5}>
            <Grid item xs={4}>
              <Stack ml={6} direction={"row"} alignItems={"center"} gap={1}>
                <ForkSvg />
                <Typography variant="h6" sx={{ color: "#0969DA" }}>
                  Twitter
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack
                justifyContent={"flex-end"}
                direction={"row"}
                alignItems={"center"}
                gap={1}
              >
                <SocialRoundSvg />
                <Typography>Social</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Step>

        <Step>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <StepLabelStyle StepIconComponent={ColorlibStepIcon}>
              Forked build-your-octocat{" "}
            </StepLabelStyle>

            <IconButton>
              <CollapseSvg />
            </IconButton>
          </Stack>

          <Grid container>
            <Grid item xs={4}>
              <Stack ml={6} direction={"row"} alignItems={"center"} gap={1}>
                <ForkSvg />
                <Typography variant="h6" sx={{ color: "#0969DA" }}>
                  build-your-octocat PFP Generator
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack
                justifyContent={"flex-end"}
                direction={"row"}
                alignItems={"center"}
                gap={1}
              >
                <ArtRoundSvg />
                <Typography>Art</Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack
                justifyContent={"flex-end"}
                direction={"row"}
                alignItems={"center"}
                gap={1}
              >
                <Typography>Apr 24</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Step>
      </Stepper>
    </Stack>
  );
}
