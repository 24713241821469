// In App.js
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from "react-router-dom";
import { baseTheme } from "./assets/global/Theme-variable";
import Layout from "./layouts";
import Frame from "./user/frame";
import Home from "./user/home";
import Buy from "./user/buy";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      { path: "home", element: <Home /> },
      { path: "buy", element: <Buy /> },
      { path: "octocat", element: <Frame /> },
      { path: "*", element: <Navigate to="/home" replace /> },
    ],
  },
]);

const App = () => {
  return (
    <ThemeProvider theme={baseTheme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

export default App; // Make sure this line is exactly like this
